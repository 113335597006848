<template>
  <div class="description">
    <!-- eslint-disable vue/no-v-html -->
    <div
      ref="element"
      class="content"
      :class="{ ellipsis: !isShowAll }"
      v-html="
        getRenderedMarkdown({
          content,
          expanded: true,
          maxLength: Infinity
        }).rendered
      "
    />
    <!-- eslint-enable vue/no-v-html -->
    <span v-if="isShowMore && !isShowAll" class="read-more" @click="toggleShowAll">
      Lire la suite
    </span>
    <span v-if="isShowMore && isShowAll" class="read-more" @click="toggleShowAll">
      Lire moins
    </span>
  </div>
</template>

<script lang="ts" setup>
import { getRenderedMarkdown } from '@/lib/utils';

defineProps<{
  content: string;
  numberOfLines: number;
}>();

const isShowAll = ref(false);
const isShowMore = ref(false);

const element = ref<HTMLElement | null>(null);

onMounted(() => {
  if (element.value) {
    isShowMore.value = element.value.scrollHeight > element.value.clientHeight;
  }
});

function toggleShowAll(): void {
  isShowAll.value = !isShowAll.value;
}
</script>

<style scoped lang="scss">
@use '$/colors.scss';
@use '$/misc.scss';
@use '$/text-ellipsis.scss';
@use '$/breakpoints.scss';

.description {
  flex-direction: column;

  .content {
    flex-direction: column;
    gap: 16px;
    white-space: pre-line;

    :deep(ul),
    :deep(ol) {
      @include breakpoints.mobile() {
        margin-left: 18px;
      }

      margin-left: 24px;
    }
  }

  .read-more {
    @include misc.bold-link();
  }

  .ellipsis {
    @include text-ellipsis.multi-line(v-bind(numberOfLines));
  }
}
</style>
